/**
 * WordPress dependencies
 */
import { __ } from '@wordpress/i18n';

/**
 * Internal dependencies
 */
import MasonryItem from './MasonryItem';
import masonryGenerator from './masonryGenerator';
import useInterval from './useInterval';
import InfiniteHits from './InfiniteHits';

/**
 * External dependencies
 */
import React, { useState } from 'react';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter/src/TypesenseInstantsearchAdapter.js';
import {
    InstantSearch,
    SearchBox,
    connectRefinementList,
    connectMenu,
    connectHierarchicalMenu,
    connectCurrentRefinements,
    Menu,
    Stats,
    Configure,
} from 'react-instantsearch-dom';
import classnames from 'classnames';
import { isEmpty, orderBy, capitalize, unescape } from 'lodash';
import Switch from 'react-switch';
import { arrowIcon } from '../icons';
import Typesense from 'typesense';

const Accordion = ({ children, title, openByDefault = false }) => {
    const [isAccordionOpen, setAccordionOpen] = useState(openByDefault);

    const accordionClasses = classnames(
        'maxi-cloud-container__accordion',
        isAccordionOpen && 'maxi-cloud-container__accordion__open'
    );

    return (
        <div className={accordionClasses}>
            <div
                onClick={() => setAccordionOpen(!isAccordionOpen)}
                className='maxi-cloud-container__accordion__title'
            >
                {title}
            </div>
            <div className='maxi-cloud-container__accordion__content'>
                {children}
            </div>
        </div>
    );
};

const resultsCount = {
    stats(nbHits) {
        const resultsString = nbHits.toLocaleString();
        return (
            <span>
                <span>{__('Returned', 'maxi-blocks')}</span>
                <strong>{` ${resultsString} `}</strong>
                <span>
                    {nbHits === 1
                        ? __('result', 'maxi-blocks')
                        : __('results', 'maxi-blocks')}
                </span>
            </span>
        );
    },
};

const RefinementList = ({ items, refine }) =>
    !isEmpty(items) && (
        <ul className='maxi-cloud-container__content__svg-categories'>
            {items.map((item) => (
                <li key={item.label} className='ais-RefinementList-item'>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        href='#'
                        onClick={(event) => {
                            event.preventDefault();
                            refine(item.value);
                        }}
                    >
                        {capitalize(item.label)} ({item.count})
                    </a>
                    <Switch
                        checked={item.isRefined}
                        onChange={(val) => refine(item.value)}
                    />
                </li>
            ))}
        </ul>
    );

const MenuSelect = ({ items, currentRefinement, refine }) => {
    const proElement = items.find((element) => element.label === 'Pro') || {
        label: 'Pro',
        value: 'Pro',
        count: 0,
        isRefined: false,
    };
    const freeElement = items.find((element) => element.label === 'Free') || {
        label: 'Free',
        value: 'Free',
        count: 0,
        isRefined: false,
    };

    return (
        <div className='top-Menu'>
            <button
                type='button'
                key='Pro'
                className={classnames(
                    'maxi-cloud-container__content-svg-shape__button',
                    proElement?.isRefined &&
                        ' maxi-cloud-container__content-svg-shape__button___pressed'
                    // proClass
                )}
                value='Pro'
                onClick={(event) => {
                    event.preventDefault();
                    refine('Pro');
                    proElement.isRefined = true;
                }}
            >
                Pro
            </button>
            <button
                type='button'
                key='Free'
                className={classnames(
                    'maxi-cloud-container__content-svg-shape__button',
                    freeElement?.isRefined &&
                        ' maxi-cloud-container__content-svg-shape__button___pressed'
                    // freeClass
                )}
                value='Free'
                onClick={(event) => {
                    event.preventDefault();
                    refine('Free');
                    freeElement.isRefined = true;
                }}
            >
                Free
            </button>
            <button
                type='button'
                value=''
                className={classnames(
                    'maxi-cloud-container__content-svg-shape__button',
                    isEmpty(currentRefinement) &&
                        ' maxi-cloud-container__content-svg-shape__button___pressed'
                )}
                onClick={(event) => {
                    event.preventDefault();
                    refine('');
                }}
            >
                {__('All', 'maxi-blocks')}
            </button>
        </div>
    );
};

const SvgMenuSelect = ({ items, currentRefinement, refine }) => {
    if (isEmpty(items)) return null;
    const finalItems = [];

    const createFinalItem = (name, index) => {
        const found = items.find((item) => item.label === name);
        if (found) finalItems[index] = found;
        else
            finalItems[index] = {
                label: name,
                value: name,
                count: 0,
                isRefined: false,
            };
    };

    createFinalItem('Filled', 0);
    createFinalItem('Shape', 1);
    createFinalItem('Line', 2);

    return (
        <div className='top-Menu'>
            {finalItems.map((item) => (
                <button
                    type='button'
                    key={item.label}
                    className={classnames(
                        'maxi-cloud-container__content-svg-shape__button',
                        item.isRefined &&
                            ' maxi-cloud-container__content-svg-shape__button___pressed'
                    )}
                    value={item.value}
                    onClick={(event) => {
                        event.preventDefault();
                        refine(item.value);
                        item.isRefined = true;
                    }}
                >
                    {item.label}
                </button>
            ))}

            <button
                type='button'
                value=''
                className={classnames(
                    'maxi-cloud-container__content-svg-shape__button',
                    isEmpty(currentRefinement) &&
                        ' maxi-cloud-container__content-svg-shape__button___pressed'
                )}
                onClick={(event) => {
                    event.preventDefault();
                    refine('');
                    items[0].isRefined = true;
                }}
            >
                {__('All', 'maxi-blocks')}
            </button>
        </div>
    );
};

const HierarchicalMenu = ({ items, refine }) =>
    !isEmpty(items) && (
        <ul>
            {items.map((item) => (
                <li
                    key={item.label}
                    className={
                        item.isRefined
                            ? 'ais-HierarchicalMenu-item ais-HierarchicalMenu-item--selected'
                            : 'ais-HierarchicalMenu-item'
                    }
                >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        href='#'
                        onClick={(event) => {
                            event.preventDefault();
                            refine(item.value);
                        }}
                    >
                        <span>
                            <span
                                className='ais-HierarchicalMenu-item-arrow'
                                visible={
                                    !isEmpty(item.items) ? 'visible' : 'hide'
                                }
                            >
                                {arrowIcon}
                            </span>
                            {unescape(item.label)}
                        </span>
                        <span>{item.count}</span>
                    </a>
                    <div className='sub_menu-wrapper'>
                        {item.items && (
                            <HierarchicalMenu
                                items={item.items}
                                refine={refine}
                            />
                        )}
                    </div>
                </li>
            ))}
        </ul>
    );

const ClearRefinements = ({ items, refine }) => (
    <div className='ais-ClearRefinements'>
        <button
            className='ais-ClearRefinements-button'
            onClick={() => {
                refine(items);
                setTimeout(() => {
                    const patternsButton = document.querySelector(
                        '.maxi-cloud-container__patterns__top-menu .ais-Menu-list > .ais-Menu-item:first-child a'
                    );
                    patternsButton?.click();
                }, '10');
            }}
        >
            {__('Clear filters', 'maxi-blocks')}
        </button>
    </div>
);

const ClearRefinementsHidden = ({ items, refine }) => (
    <div className='ais-ClearRefinements maxi-hidden maxi-clear-for-type'>
        <button
            className='ais-ClearRefinements-button'
            onClick={() => {
                refine(items);
            }}
        >
            {__('Clear Hidden', 'maxi-blocks')}
        </button>
    </div>
);

const MenuSC = ({ items, refine }) => (
    <ul className='maxi-cloud-container__content__svg-categories'>
        {items.map((item) => (
            <li
                key={item.label}
                className={
                    item.isRefined
                        ? 'ais-RefinementList-item ais-RefinementList-item--selected'
                        : 'ais-RefinementList-item'
                }
            >
                <a
                    href='#'
                    onClick={(event) => {
                        event.preventDefault();
                        refine(item.value);
                    }}
                >
                    <span>{capitalize(item.label)}</span>
                    <span>{item.count}</span>
                </a>
            </li>
        ))}
    </ul>
);

/**
 * Component
 */
const LibraryContainer = (props) => {
    const { type, url, title } = props;

    const typesenseInstantsearchAdapter = (params) => {
        return new TypesenseInstantSearchAdapter({
            server: {
                apiKey: process.env.REACT_APP_TYPESENSE_API_KEY, // Be sure to use an API key that only allows search operations
                nodes: [
                    {
                        host: process.env.REACT_APP_TYPESENSE_API_URL,
                        port: '443',
                        protocol: 'https',
                    },
                ],
            },
            // The following parameters are directly passed to Typesense's search API endpoint.
            //  So you can pass any parameters supported by the search endpoint below.
            //  query_by is required.
            additionalSearchParameters: {
                query_by: params,
                sort_by: 'post_date_int:desc',
            },
        });
    };

    const searchClientPatterns = typesenseInstantsearchAdapter(
        'post_title, post_number, category.lvl0, category.lvl1, cost'
    ).searchClient;

    const searchClientSc = typesenseInstantsearchAdapter(
        'post_title, sc_color'
    ).searchClient;

    const searchClientSvg = typesenseInstantsearchAdapter(
        'post_title, svg_tag, svg_category'
    ).searchClient;

    let client = new Typesense.Client({
        nodes: [
            {
                host: process.env.REACT_APP_TYPESENSE_API_URL, // For Typesense Cloud use xxx.a1.typesense.net
                port: '443', // For Typesense Cloud use 443
                protocol: 'https', // For Typesense Cloud use https
            },
        ],
        apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
        connectionTimeoutSeconds: 2,
    });

    const searchParametersAllPosts = {
        q: '',
        query_by: 'post_id',
        sort_by: 'post_date_int:asc',
    };

    const allPosts = client
        .collections('post')
        .documents()
        .search(searchParametersAllPosts);

    const searchParametersAllPatterns = {
        q: '',
        query_by: 'post_id',
        filter_by: 'gutenberg_type:Patterns',
        sort_by: 'post_date_int:asc',
    };

    const allPatterns = client
        .collections('post')
        .documents()
        .search(searchParametersAllPatterns);

    const searchParametersAllPages = {
        q: '',
        query_by: 'post_id',
        filter_by: 'gutenberg_type:Pages',
        sort_by: 'post_date_int:asc',
    };

    const allPages = client
        .collections('post')
        .documents()
        .search(searchParametersAllPages);

    const searchParametersAllPlayground = {
        q: '',
        query_by: 'post_id',
        filter_by: 'gutenberg_type:Playground',
        sort_by: 'post_date_int:asc',
    };

    const allPlayground = client
        .collections('post')
        .documents()
        .search(searchParametersAllPlayground);

    const searchParametersAllTheme = {
        q: '',
        query_by: 'post_id',
        filter_by: 'gutenberg_type:Theme',
        sort_by: 'post_date_int:asc',
    };

    const allTheme = client
        .collections('post')
        .documents()
        .search(searchParametersAllTheme);

    const searchParametersAllIcons = {
        q: '',
        query_by: 'post_id',
        sort_by: 'post_date_int:asc',
    };

    const allIcons = client
        .collections('svg_icon')
        .documents()
        .search(searchParametersAllIcons);

    const searchParametersAllScs = {
        q: '',
        query_by: 'post_id',
        sort_by: 'post_date_int:asc',
    };

    const allScs = client
        .collections('style_card')
        .documents()
        .search(searchParametersAllScs);

    const [postTotal, setPostTotal] = useState('');
    const [patternsTotal, setPattensTotal] = useState('');
    const [pagesTotal, setPagesTotal] = useState('');
    const [playgroundTotal, setPlaygroundTotal] = useState('');
    const [themeTotal, setThemeTotal] = useState('');
    const [iconTotal, setIconTotal] = useState('');
    const [scTotal, setScTotal] = useState('');

    const addCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const getAllTotal = () => {
        if (postTotal === '') return '';
        const total =
            parseInt(postTotal) + parseInt(iconTotal) + parseInt(scTotal);
        return addCommas(total);
    };

    if (postTotal === '' && patternsTotal === '' && iconTotal === '') {
        allPosts.then((response) => {
            //console.log(response);
            setPostTotal(response.out_of);
        });

        allPatterns.then((response) => {
            //console.log(response);
            setPattensTotal(response.found);
        });

        allPages.then((response) => {
            // console.log(response);
            setPagesTotal(response.found);
        });

        allPlayground.then((response) => {
            //console.log(response);
            setPlaygroundTotal(response.found);
        });

        allTheme.then((response) => {
            //console.log(response);
            setThemeTotal(response.found);
        });

        allIcons.then((response) => {
            //console.log(response);
            setIconTotal(response.out_of);
        });

        allScs.then((response) => {
            //console.log(response);
            setScTotal(response.out_of);
        });
    }

    /** Patterns / Blocks Results */
    const patternsResults = ({ hit }) => {
        const wrapClassName =
            hit.cost?.[0] === 'Pro'
                ? 'ais-InfiniteHits-item-pro'
                : 'ais-InfiniteHits-item-free';
        return (
            <MasonryItem
                type='patterns'
                target='patterns'
                key={`maxi-cloud-masonry__item-${hit.post_id}`}
                demoUrl={hit.demo_url}
                toneUrl={hit.link_to_related}
                previewIMG={hit.preview_image_url}
                cost={hit.cost?.[0]}
                isPro={hit.cost?.[0] === 'Pro'}
                taxonomies={hit.category?.[0]}
                serial={hit.post_title}
                title={hit.post_title}
                className={wrapClassName}
            />
        );
    };

    /** SVG Icons Results */
    const svgResults = ({ hit }) => {
        const newContent = hit.svg_code;
        const svgType = hit.svg_category[0];

        return (
            <MasonryItem
                type='svg'
                target={svgType}
                key={`maxi-cloud-masonry__item-${hit.post_id}`}
                svgCode={newContent}
                isPro={hit.cost === 'Pro'}
                serial={hit.post_title}
            />
        );
    };

    /** Shapes Results */
    const svgShapeResults = ({ hit }) => {
        const newContent = hit.svg_code;
        return (
            <MasonryItem
                type='svg'
                target={type}
                key={`maxi-cloud-masonry__item-${hit.post_id}`}
                svgCode={newContent}
                isPro={hit.cost === 'Pro'}
                serial={hit.post_title}
            />
        );
    };

    /** Style Cards Results */
    const scResults = ({ hit }) => {
        return (
            <MasonryItem
                type='sc'
                target='style-cards'
                key={`maxi-cloud-masonry__item-${hit.post_id}`}
                previewIMG={hit.post_thumbnail}
                isPro={hit.cost === 'Pro'}
                serial={hit.post_title}
            />
        );
    };

    const CustomRefinementList = connectRefinementList(RefinementList);

    const CustomMenuSelect = connectMenu(MenuSelect);
    const CustomMenuSC = connectMenu(MenuSC);
    const CustomSvgMenuSelect = connectMenu(SvgMenuSelect);
    const CustomHierarchicalMenu = connectHierarchicalMenu(HierarchicalMenu);

    const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);
    const CustomClearRefinementsHidden = connectCurrentRefinements(
        ClearRefinementsHidden
    );

    useInterval(masonryGenerator, 100);

    const maxiPreviewIframe = (url, title) => {
        return (
            <>
                <div
                    className='maxi-cloud-container__preview-tablet__label'
                    style={{ display: 'none' }}
                >
                    {__(
                        'Tablet / iPad simulator | Viewport 768px x 1024px',
                        'maxi-blocks'
                    )}
                </div>
                <div
                    className='maxi-cloud-container__preview-mobile__label'
                    style={{ display: 'none' }}
                >
                    {__(
                        'Mobile / iPhone simulator | Viewport 390px x 844px',
                        'maxi-blocks'
                    )}
                </div>
                <div className='maxi-cloud-container__preview-iframe_main-wrap'>
                    <div className='maxi-cloud-container__preview-iframe_wrap'>
                        <div>
                            <iframe
                                className='maxi-cloud-container__preview-iframe'
                                src={url}
                                title={title}
                                width='100%'
                                height='100%'
                            />
                        </div>
                    </div>
                    <div className='maxi-cloud-container__preview-iframe_space'></div>
                </div>
            </>
        );
    };

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const getDefaultMenuCost = () => {
        const cost = urlParams?.get('plan');
        switch (cost) {
            case 'pro':
                return 'Pro';
            case 'free':
                return 'Free';
            default:
                return '';
        }
    };

    const getDefaultMenuType = () => {
        const type = urlParams?.get('type');
        switch (type) {
            case 'pages':
                return 'Pages';
            case 'playground':
                return 'Playground';
            case 'theme':
                return 'Theme';
            default:
                return 'Patterns';
        }
    };

    const getDefaultMenuTone = () => {
        const type = urlParams?.get('tone');
        switch (type) {
            case 'light':
                return 'Light';
            case 'dark':
                return 'Dark';
            default:
                return '';
        }
    };

    const processTypeMenuClick = () => {
        const onClickTypeButton = (button) => {
            button.addEventListener(
                'click',
                function (e) {
                    e.preventDefault();
                    const clearButton = document.querySelector(
                        '.maxi-clear-for-type .ais-ClearRefinements-button'
                    );
                    clearButton?.click();
                },
                false
            );
        };
        const typeButtons = document.querySelectorAll(
            '.maxi-cloud-container__patterns__top-menu .ais-Menu-list .ais-Menu-item a'
        );

        for (let i = 0; i < typeButtons?.length; i++) {
            onClickTypeButton(typeButtons[i]);
        }
    };

    setTimeout(() => {
        processTypeMenuClick();

        document
            .querySelector('.maxi-cloud-toolbar__logo')
            .after(
                document.querySelector(
                    '.maxi-cloud-container__patterns__top-menu'
                )
            );
    }, '100');

    const iconButtonWp = () => {
        return (
            <svg
                class='wordpress-2-shape-maxi-svg'
                width='28px'
                height='28px'
                viewBox='0 0 36.1 36.1'
            >
                <path
                    d='M18.05.13C8.169.13.13 8.169.13 18.05s8.039 17.92 17.92 17.92 17.92-8.039 17.92-17.92S27.931.13 18.05.13zm0 2.24a15.57 15.57 0 0 1 8.575 2.569c-.858.477-1.653 1.481-1.653 3.613 0 1.133.773 2.448 1.667 3.972 1.073 1.828 2.404 4.101 2.07 5.743-.367 1.086-1.299 3.553-2.309 6.185l-4.63-13.306c.094-.025.199-.04.289-.069a1.12 1.12 0 0 0 .708-1.416c-.197-.587-.829-.898-1.418-.708-.954.318-2.681.311-3.718.309h-.311-.311c-1.03.002-2.764.009-3.718-.309a1.12 1.12 0 0 0-1.413.708c-.197.587.121 1.221.708 1.416.473.159 1.03.244 1.599.307l2.755 7.164-2.605 6.572-4.451-13.924c.164-.038.338-.067.488-.119.587-.195.905-.829.708-1.416a1.12 1.12 0 0 0-1.416-.708c-.952.318-2.684.309-3.721.309h-.307c-.202 0-.367-.016-.538-.029C7.921 5.094 12.672 2.37 18.05 2.37zM2.37 18.05c0-1.761.305-3.45.842-5.031l6.872 18.514C5.477 28.798 2.37 23.787 2.37 18.05zm15.68 15.68c-1.55 0-3.044-.235-4.46-.654l4.534-11.442 4.399 11.44a15.69 15.69 0 0 1-4.473.656zm8.017-2.231l4.702-12.345.029.009c.031-.09.065-.206.087-.343a53.63 53.63 0 0 0 .641-1.888c.553-1.749.905-3.288 1.089-4.65a15.54 15.54 0 0 1 1.115 5.768c0 5.714-3.082 10.709-7.663 13.449z'
                    fill='#fff'
                ></path>
            </svg>
        );
    };

    return (
        <div className='maxi-cloud-container'>
            {type === 'svg' && (
                <div className='maxi-cloud-container__svg-icon'>
                    <InstantSearch
                        indexName='svg_icon'
                        searchClient={searchClientSvg}
                    >
                        <Configure hitsPerPage={104} />
                        <div className='maxi-cloud-container__svg-icon__sidebar'>
                            <SearchBox
                                submit={__('Find', 'maxi-blocks')}
                                autoFocus
                                searchAsYouType
                            />
                            <CustomHierarchicalMenu
                                attributes={['svg_tag.lvl0', 'svg_tag.lvl1']}
                                limit={20}
                                transformItems={(items) =>
                                    items.filter((item) => item.label !== '')
                                }
                            />
                            <CustomClearRefinements />
                        </div>
                        <div className='maxi-cloud-container__content-svg-shape'>
                            <div className='maxi-cloud-container__sc__content-sc'>
                                <Stats translations={resultsCount} />
                                <div className='maxi-cloud-container__content-svg-shape__search-bar'>
                                    <CustomSvgMenuSelect
                                        className='maxi-cloud-container__content-svg-shape__categories'
                                        attribute='svg_category'
                                        // defaultRefinement='Filled'
                                        translations={{
                                            seeAllOption: __(
                                                'All',
                                                'maxi-blocks'
                                            ),
                                        }}
                                    />
                                </div>
                                <InfiniteHits
                                    hitComponent={svgResults}
                                    type='svg'
                                />
                            </div>
                        </div>
                    </InstantSearch>
                </div>
            )}

            {(type.includes('shape') || type === 'video-icon') && (
                <InstantSearch
                    indexName='svg_icon'
                    searchClient={searchClientSvg}
                >
                    <Configure hitsPerPage={49} />
                    <div className='maxi-cloud-container__svg-shape'>
                        <div className='maxi-cloud-container__svg-shape__sidebar maxi-cloud-container__hide-categories'>
                            <SearchBox
                                submit={__('Find', 'maxi-blocks')}
                                autoFocus
                                searchAsYouType
                            />
                            <CustomRefinementList
                                className='hidden'
                                attribute='svg_category'
                                defaultRefinement={['Shape']}
                            />
                        </div>
                        <div className='maxi-cloud-container__content-svg-shape'>
                            <div className='maxi-cloud-container__sc__content-sc'>
                                <Stats translations={resultsCount} />
                                <InfiniteHits hitComponent={svgShapeResults} />
                            </div>
                        </div>
                    </div>
                </InstantSearch>
            )}

            {type === 'button-icon' && (
                <InstantSearch
                    indexName='svg_icon'
                    searchClient={searchClientSvg}
                >
                    <Configure hitsPerPage={49} />
                    <div className='maxi-cloud-container__svg-shape'>
                        <div className='maxi-cloud-container__svg-shape__sidebar'>
                            <SearchBox
                                submit={__('Find', 'maxi-blocks')}
                                autoFocus
                                searchAsYouType
                            />
                            <CustomRefinementList
                                className='hidden'
                                attribute='svg_category'
                                defaultRefinement={['Line']}
                            />
                        </div>
                        <div className='maxi-cloud-container__content-svg-shape'>
                            <div className='maxi-cloud-container__sc__content-sc'>
                                <Stats translations={resultsCount} />
                                <InfiniteHits hitComponent={svgShapeResults} />
                            </div>
                        </div>
                    </div>
                </InstantSearch>
            )}

            {(type === 'preview' || type === 'switch-tone') && (
                <div className='maxi-cloud-container__patterns'>
                    {maxiPreviewIframe(url, title)}
                </div>
            )}

            {type === 'patterns' && (
                <div className='maxi-cloud-container__patterns'>
                    <InstantSearch
                        indexName='post'
                        searchClient={searchClientPatterns}
                    >
                        <Configure hitsPerPage={20} />
                        <div className='maxi-cloud-container__patterns__top-menu'>
                            <div className='maxi-cloud-container__patterns__top-menu__wrap'>
                                <div className='maxi-cloud-container__patterns__top-menu__total'>
                                    Total: {getAllTotal()}
                                </div>
                                <Menu
                                    attribute='gutenberg_type'
                                    defaultRefinement={getDefaultMenuType()}
                                    transformItems={(items) => {
                                        if (!items) return;
                                        const generateItem = (name, number) => {
                                            const item = items?.find(
                                                (item) => item.label === name
                                            );
                                            if (item)
                                                return {
                                                    ...item,
                                                    ...{
                                                        count: addCommas(
                                                            number
                                                        ),
                                                    },
                                                };
                                            return {
                                                label: name,
                                                value: name,
                                                count: addCommas(number),
                                                isRefined: false,
                                            };
                                        };

                                        const itemsReturn = [];
                                        itemsReturn.push(
                                            generateItem(
                                                'Patterns',
                                                patternsTotal
                                            )
                                        );

                                        itemsReturn.push(
                                            generateItem('Pages', pagesTotal)
                                        );
                                        itemsReturn.push(
                                            generateItem(
                                                'Playground',
                                                playgroundTotal
                                            )
                                        );

                                        itemsReturn.push(
                                            generateItem(
                                                'Theme',
                                                themeTotal
                                            )
                                        );

                                        return itemsReturn;
                                    }}
                                />
                                <div className='maxi-cloud-container__patterns__top-menu__wrap-buttons'>
                                    <button
                                        className='maxi-cloud-container__patterns__top-menu__show_button'
                                        id='maxi-show_button__patterns'
                                    >
                                        <span className='ais-Menu-label'>
                                            Patterns
                                        </span>{' '}
                                        <span className='ais-Menu-count'>
                                            {addCommas(patternsTotal)}
                                        </span>
                                    </button>
                                    <button
                                        className='maxi-cloud-container__patterns__top-menu__show_button'
                                        id='maxi-show_button__pages'
                                    >
                                        <span className='ais-Menu-label'>
                                            Pages
                                        </span>{' '}
                                        <span className='ais-Menu-count'>
                                            {addCommas(pagesTotal)}
                                        </span>
                                    </button>
                                    <button
                                        className='maxi-cloud-container__patterns__top-menu__show_button'
                                        id='maxi-show_button__icons'
                                    >
                                        <span className='ais-Menu-label'>
                                            Icons
                                        </span>{' '}
                                        <span className='ais-Menu-count'>
                                            {addCommas(iconTotal)}
                                        </span>
                                    </button>
                                    <button
                                        className='maxi-cloud-container__patterns__top-menu__show_button'
                                        id='maxi-show_button__sc'
                                    >
                                        <span className='ais-Menu-label'>
                                            Style cards
                                        </span>{' '}
                                        <span className='ais-Menu-count'>
                                            {addCommas(scTotal)}
                                        </span>
                                    </button>
                                    <button
                                        className='maxi-cloud-container__patterns__top-menu__show_button'
                                        id='maxi-show_button__playground'
                                    >
                                        <span className='ais-Menu-label'>
                                            Playground
                                        </span>{' '}
                                        <span className='ais-Menu-count'>
                                            {addCommas(playgroundTotal)}
                                        </span>
                                    </button>
                                    <button
                                        className='maxi-cloud-container__patterns__top-menu__show_button'
                                        id='maxi-show_button__theme'
                                    >
                                        <span className='ais-Menu-label'>
                                            Theme
                                        </span>{' '}
                                        <span className='ais-Menu-count'>
                                            {addCommas(themeTotal)}
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <a
                                type='button'
                                label='Download plugin'
                                className='components-button maxi-cloud-container__patterns__top-menu__button-go-pro download-button'
                                href='https://wordpress.org/plugins/maxi-blocks/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                {iconButtonWp()}
                                <span>
                                    {__('Download plugin', 'maxi-blocks')}
                                </span>
                            </a>
                            <a
                                type='button'
                                label='Go Pro'
                                className='components-button maxi-cloud-container__patterns__top-menu__button-go-pro'
                                href='https://maxiblocks.com/go/pro-library'
                                target='_blank'
                                rel='noreferrer'
                            >
                                {__('Go Pro', 'maxi-blocks')}
                            </a>
                        </div>
                        <div className='maxi-cloud-container__patterns__sidebar'>
                            <CustomMenuSelect
                                className='maxi-cloud-container__content-patterns__cost'
                                attribute='cost'
                                defaultRefinement={getDefaultMenuCost()}
                            />
                            <Menu
                                attribute='light_or_dark'
                                facetOrdering={false}
                                defaultRefinement={getDefaultMenuTone()}
                                transformItems={(items) => {
                                    const generateItem = (name) => {
                                        const item = items?.find(
                                            (item) => item.label === name
                                        );
                                        if (item) {
                                            item.label = name;
                                            return item;
                                        }
                                        return {
                                            label: name,
                                            value: name,
                                            count: 0,
                                            isRefined: false,
                                        };
                                    };
                                    const itemsReturn = [];
                                    itemsReturn.push(generateItem('Light'));
                                    itemsReturn.push(generateItem('Dark'));
                                    return itemsReturn;
                                }}
                            />
                            <SearchBox
                                autoFocus
                                searchAsYouType
                                reset='X'
                                translations={{
                                    resetTitle: 'Clear',
                                }}
                            />
                            <CustomHierarchicalMenu
                                attributes={['category.lvl0', 'category.lvl1']}
                                limit={20}
                            />
                            <CustomClearRefinements />
                            <CustomClearRefinementsHidden
                                transformItems={(items) =>
                                    items.filter(
                                        (item) =>
                                            item.attribute !== 'gutenberg_type'
                                    )
                                }
                            />
                        </div>
                        <div className='maxi-cloud-container__patterns__content-patterns'>
                            <Stats translations={resultsCount} />
                            <InfiniteHits
                                hitComponent={patternsResults}
                                type='patterns'
                            />
                        </div>
                    </InstantSearch>
                </div>
            )}
            {type === 'sc' && (
                <div className='maxi-cloud-container__sc'>
                    <InstantSearch
                        indexName='style_card'
                        searchClient={searchClientSc}
                    >
                        <Configure hitsPerPage={20} />
                        <div className='maxi-cloud-container__sc__sidebar'>
                            <SearchBox autoFocus searchAsYouType />
                            <CustomMenuSC
                                attribute='sc_color'
                                limit={100}
                                transformItems={(items) =>
                                    orderBy(items, 'label', 'asc')
                                }
                            />
                            <CustomClearRefinements />
                        </div>
                        <div className='maxi-cloud-container__sc__content-sc'>
                            <Stats translations={resultsCount} />
                            <InfiniteHits hitComponent={scResults} type='sc' />
                        </div>
                    </InstantSearch>
                </div>
            )}
        </div>
    );
};

export default LibraryContainer;
